import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Model } from "survey-core";
import { ToastContainer, toast } from "react-toastify";
import { Helmet } from "react-helmet";

import SurveyPage, { ISurvey } from "../components/Survey";
import { BouncingDotsIndicator } from "../components/BouncingDotsIndicator";
import { toProgramType, trackEvent } from "../analytics/ga";

import "./Program.css";
import classNames from "classnames";

function ProgramSurvey() {
  const { programName } = useParams();
  const { surveyName } = useParams();
  const { userExtId } = useParams();

  const [survey, setSurvey] = useState<ISurvey>();
  const [error, setError] = useState<boolean>(false);

  const endpoint = `/api/program-survey`;
  const onSubmit = async (
    data: any,
    done: (success: boolean, clearData?: boolean) => void
  ) => {
    const surveyId = data.id;
    trackEvent("follow_up_survey_complete", toProgramType(programName), {
      surveyName,
    });

    const response = await fetch(`${endpoint}/${surveyId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...data,
        userExtId,
        programName,
      }),
    });

    if (response.status !== 200) {
      toast.error(
        "Tietojen tallennus epäonnistui. Laitathan meille viestiä osoitteeseen info@viven.fi mikäli ongelma ei ratkea.",
        {
          autoClose: 3000,
        }
      );
      done(false);
    } else {
      done(true, true);
    }
  };

  const onAnswer = async (data: any) => {
    const surveyId = data.id;
    const response = await fetch(`${endpoint}/unfinished/${surveyId}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        ...data,
        userExtId,
        programName,
      }),
    });
    if (response.status !== 200) {
      // Fail silently for now
      console.log("Failed to save unfinished survey");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const endpoint = `/api/program-survey`;
      const response = await fetch(
        `${endpoint}/${programName}/${surveyName}/${userExtId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status !== 200) {
        setError(true);
      } else {
        const surveyJson = await response.json();
        setSurvey({
          id: surveyJson.id,
          model: new Model(surveyJson.data),
          programName,
        });
        trackEvent("follow_up_survey_start", toProgramType(programName), {
          surveyName,
        });
      }
    };
    fetchData().catch(console.error);
  }, [programName, surveyName, userExtId]);

  return (
    <>
      <div className={classNames("program")}>
        <Helmet>
          <title>Naisen kattava terveyskysely – Vested Health</title>
          <meta
            name="description"
            content="Hormonaalista ja kokonaisvaltaista terveyttä ja hyvinvointia kartoittava kysely"
          />
        </Helmet>
        <ToastContainer theme="light" />
        {error ? (
          <div className="error">Hakemaasi sisältöä ei löytynyt</div>
        ) : survey ? (
          <SurveyPage
            id={survey.id}
            model={survey.model}
            programName={programName}
            onSubmit={onSubmit}
            onAnswer={onAnswer}
          />
        ) : (
          <div className="loading">
            <BouncingDotsIndicator />
          </div>
        )}
      </div>
    </>
  );
}

export default ProgramSurvey;
