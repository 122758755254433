import "survey-core/defaultV2.min.css";
import "survey-core/survey.i18n";
import "./Survey.css";
import { Model, surveyLocalization } from "survey-core";
import { Survey } from "survey-react-ui";
import { Converter } from "showdown";

import surveyTheme from "./theme.json";
import { toProgramType, trackEvent } from "../analytics/ga";

const storageItemKey = "vested-weightloss-survey";

export interface ISurvey {
  id: number;
  model: Model;
  programName: string | undefined;
}

function animate(oldClass: string, newClass: string, isGoingForward: boolean) {
  const element = document.getElementById("root");
  if (!!element) {
    const classes = element.classList;
    classes.remove(oldClass, "forward", "backward");
    classes.add(newClass, isGoingForward ? "forward" : "backward");
  }
}
function animateIn(isGoingForward: boolean) {
  animate("hidden", "visible", isGoingForward);
}
function animateOut(isGoingForward: boolean) {
  animate("visible", "hidden", isGoingForward);
}

function saveStateToSessionStorage(survey: Model) {
  const data = survey.data;
  data.pageNo = survey.currentPageNo;
  window.sessionStorage.setItem(storageItemKey, JSON.stringify(data));
}

function SurveyComponent({
  id,
  model,
  programName,
  onSubmit,
  onAnswer,
}: {
  id: number;
  model: Model;
  programName: string | undefined;
  onSubmit: (
    data: { id: number; result: any },
    done: (success: boolean, clearData?: boolean) => void
  ) => void;
  onAnswer?: (data: { id: number; result: any }) => void;
}) {
  var isPageChanging = false;
  const originalComplete = model.completedHtml;

  model.onAfterRenderSurvey.add(() => {
    animateIn(true);
  });
  model.onCurrentPageChanging.add((_, options) => {
    if (isPageChanging) return;
    options.allow = false;
    setTimeout(function () {
      isPageChanging = true;
      model.currentPage = options.newCurrentPage;
      isPageChanging = false;
    }, 500);
    animateOut(options.isGoingForward);
  });
  model.onCurrentPageChanged.add((survey, options) => {
    // Last page name must be 'acceptterms' to trigger ga event
    if (options.newCurrentPage.name === "acceptterms") {
      trackEvent("survey_complete", toProgramType(programName));
    }

    animateIn(options.isGoingForward);

    saveStateToSessionStorage(survey);
  });
  model.onValueChanged.add((sender) => {
    const data = {
      id,
      result: sender.data,
    };
    onAnswer && onAnswer(data);
  });
  model.onCompleting.add((sender, _options) => {
    saveStateToSessionStorage(sender);
  });
  model.onComplete.add((sender, _options) => {
    sender.completedHtml = `
      <div class="submitting">
        <div class="douncing-dots">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>`;
    const data = {
      id,
      result: sender.data,
    };
    onSubmit(data, (success: boolean, clearData?: boolean) => {
      if (success) {
        sender.completedHtml = originalComplete;
        sender.showCompletedPage = true;
        clearData === true && window.sessionStorage.removeItem(storageItemKey);
      } else {
        sender.clear(false, false);
      }
    });
  });
  const converter = new Converter({
    openLinksInNewWindow: true,
  });
  model.onTextMarkdown.add(function (_survey, options) {
    // Convert Markdown to HTML
    let str = converter.makeHtml(options.text);
    // Remove root paragraphs <p></p>
    str = str.substring(3);
    str = str.substring(0, str.length - 4);
    // Set HTML markup to render
    options.html = str;
  });

  surveyLocalization.defaultLocale = "fi";
  model.locale = "fi";

  model.applyTheme(surveyTheme);

  // Restore survey results
  const prevData = window.sessionStorage.getItem(storageItemKey) || null;
  if (prevData) {
    const data = JSON.parse(prevData);
    model.data = data;
    if (data.pageNo) {
      model.currentPageNo = data.pageNo;
    }
  }

  return (
    <div className="container">
      <Survey model={model} />
    </div>
  );
}

export default SurveyComponent;
