import React from "react";
import ReactDOM from "react-dom/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import {
  ReactPlugin,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga4";
import App from "./pages/App";
import Login from "./pages/Login";
import Program from "./pages/Program";
import ProgramRegistered from "./pages/ProgramRegistered";
import ProgramSurvey from "./pages/ProgramSurvey";
import Redirect from "./pages/Redirect";
import Error from "./pages/Error";
import Page404 from "./pages/Page404";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

ReactGA.initialize("G-CBF4JR7KSG", {
  testMode: process.env.NODE_ENV !== "production",
});

const root = ReactDOM.createRoot(document.getElementById("root"));

const applicationInsightsConnectionString =
  process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING;

var reactPlugin = new ReactPlugin();

if (applicationInsightsConnectionString) {
  console.log("Application Insights enabled");
  const browserHistory = createBrowserHistory({ basename: "" });

  var clickPluginInstance = new ClickAnalyticsPlugin();
  var clickPluginConfig = {
    autoCapture: true,
  };
  var appInsights = new ApplicationInsights({
    config: {
      connectionString: applicationInsightsConnectionString,
      extensions: [reactPlugin, clickPluginInstance],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
    },
  });
  appInsights.loadAppInsights();
}

root.render(
  <AppInsightsErrorBoundary onError={() => <Error />} appInsights={reactPlugin}>
    <Router>
      <Routes>
        <Route
          path="/palvelut/:programName/kysely/:surveyName/:userExtId"
          element={<ProgramSurvey />}
        ></Route>
        <Route
          path="/palvelut/:programName/kiitos/:userExtId"
          element={<ProgramRegistered />}
        ></Route>
        <Route path="/palvelut/:programName" element={<Program />}></Route>
        <Route path="/redirect/:eventName" element={<Redirect />}></Route>
        <Route path="/404" element={<Page404 />}></Route>

        <Route path="/*" element={<App />}></Route>
      </Routes>
    </Router>
  </AppInsightsErrorBoundary>
);
