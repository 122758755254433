import { useParams } from "react-router-dom";
import PerimenopauseCardImg from "./perimenopause-program.jpg";
import { useEffect } from "react";

const storageItemKey = "vested-weightloss-survey";

function ProgramRegistered() {
  const { userExtId } = useParams();
  const { programName } = useParams();

  useEffect(() => {
    window.sessionStorage.removeItem(storageItemKey);
  });

  return (
    <div className="program">
      <div className="registered">
        {userExtId && programName && (
          <>
            <img
              style={{
                width: "100%",
                objectFit: "cover",
                borderRadius: "8px",
              }}
              height={"300"}
              width={"80%"}
              src={PerimenopauseCardImg}
              alt="Program"
            />
            <h4>Hienoa – tervetuloa mukaan pilottiasiakkaaksi!</h4>
            <p>
              Saat meiltä seuraavien päivien aikana jatko-ohjeet
              laboratoriokäynnin varaamiseen sekä linkin terveyskyselyyn.
            </p>
            <p>
              Kiitos, että olet mukana auttamassa meitä luomaan palvelu, josta
              on aidosti hyötyä sinulle ja kaltaisillesi naisille 🙏
            </p>
            <p>
              Terkuin,
              <br />
              Jenni Hyysalo
              <br />
              Lääketieteen tohtori, elintapalääkäri
            </p>
          </>
        )}
      </div>
    </div>
  );
}

export default ProgramRegistered;
