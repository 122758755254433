import { Link } from "react-router-dom";
import "./Welcome.css";

import MainBg from "./main-page.jpg";
import Logo from "./viven-white.svg";

export default function Welcome() {
  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        background: `url(${MainBg}) no-repeat center center`,
        backgroundSize: "cover",
        display: "flex",
      }}
    >
      <div className="welcome-link-container">
        <Link className="welcome-link" to={`https://viven.fi`} target="_top">
          <img src={Logo} alt="Viven" />
        </Link>
      </div>
    </div>
  );
}
