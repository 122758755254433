import React from "react";
import { CSSTransition } from "react-transition-group";
import "./FadeIn.css";

export function FadeIn({ children }: { children: React.ReactNode }) {
  return (
    <CSSTransition
      in={true}
      appear={true}
      timeout={1500}
      classNames="fadein-component"
      unmountOnExit
    >
      {children}
    </CSSTransition>
  );
}
