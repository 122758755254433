import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { BouncingDotsIndicator } from "../components/BouncingDotsIndicator";
import { toProgramType, trackEvent } from "../analytics/ga";

import "./Program.css";

function Redirect() {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    trackEvent(
      "identification_complete",
      toProgramType(searchParams.get("program"))
    );

    const redirectUrl = searchParams.get("url");
    if (redirectUrl !== null) {
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 500);
    }
  });

  return (
    <div className="program">
      <div className="loading">
        <BouncingDotsIndicator />
      </div>
    </div>
  );
}

export default Redirect;
