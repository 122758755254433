import classNames from "classnames";
import moment from "moment";
import { User, Program } from "@backend/types";

import WeightLossCardImg from "./weightloss-program.png";
import { FadeIn } from "../components/animations/FadeIn";

import styles from "./Bootstrap.module.scss";

const translations = {
  fi: {
    programs: {
      weightloss: {
        title: "Painonpudotusohjelma",
        description:
          "12 kuukauden ohjattu painonpudotusohjelma, joka auttaa sinua saavuttamaan tavoitteesi.",
      },
      status: {
        pending: "Odottaa vahvistusta",
        accepted: "Hyväksytty",
        active: "Käynnissä",
        finished: "Päättynyt",
        rejected: "Hylätty",
      },
    },
    status: "Tila",
  },
};
const langauge = "fi";

export function MainPage({ user }: { user: User | undefined }) {
  return (
    <div className={styles.bootstrap}>
      <div className={styles["container-fluid"]}>
        {user === undefined ? (
          <></>
        ) : (
          <div className={styles["mt-3"]}>
            <h3>Omat tiedot</h3>
            <div>
              <div className={styles["row"]}>
                <div className={styles["col-2"]}>Nimi:</div>
                <div className={styles["col"]}>
                  {user.firstName} {user.lastName}
                </div>
              </div>
              <div className={styles["row"]}>
                <div className={styles["col-2"]}>Sähköposti:</div>
                <div className={styles["col"]}>{user.email}</div>
              </div>
              <div className={styles["row"]}>
                <div className={styles["col-2"]}>Puhelin:</div>
                <div className={styles["col"]}>{user.phone}</div>
              </div>
              <div className={styles["row"]}>
                <div className={styles["col-2"]}>Rekisteröinti pvm:</div>
                <div className={styles["col"]}>
                  {moment(user.createdAt).format("DD.MM.YYYY")}
                </div>
              </div>
            </div>
            <hr />
            {user.programs.map((program: Program, index: number) => (
              <div
                className={classNames(styles["program-card"], styles["card"])}
                style={{ width: "18rem" }}
                key={index}
              >
                <FadeIn>
                  <img
                    className={styles["card-img-top"]}
                    src={WeightLossCardImg}
                    alt="WeightLoss"
                  />
                </FadeIn>
                <div className={styles["card-body"]}>
                  <h5 className={styles["card-title"]}>
                    {translations[langauge].programs.weightloss.title}
                  </h5>
                  <p className={styles["card-text"]}>
                    {translations[langauge].programs.weightloss.description}
                  </p>
                  <small className={styles["card-text"]}>
                    {translations[langauge].status}:{" "}
                    {translations[langauge].programs.status[program.status]}
                  </small>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
