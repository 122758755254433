import { User } from "@backend/types";
import { useEffect, useState } from "react";
import { Link, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { BouncingDotsIndicator } from "../components/BouncingDotsIndicator";
import { MainPage } from "./Main";

import Logo from "../static/images/logo.svg";

import classNames from "classnames";
import "./App.css";
import styles from "./Bootstrap.module.scss";
import Welcome from "./Welcome";

export default function App() {
  const [user, setUser] = useState<User | undefined | null>(undefined);

  useEffect(() => {
    const fetchUser = async () => {
      const endpoint = `/api/user`;
      const response = await fetch(`${endpoint}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 401) {
        setUser(null);
      } else {
        const userJson = await response.json();
        setUser(userJson);
      }
    };
    fetchUser().catch(console.error);
  }, []);

  return user === null ? (
    <div className={styles.bootstrap}>
      <Welcome />
    </div>
  ) : (
    <div className={styles.bootstrap}>
      <ToastContainer theme="light" />
      {user === undefined ? (
        <div className="centered">
          <BouncingDotsIndicator />
        </div>
      ) : (
        <>
          <nav
            className={classNames(
              styles["navbar"],
              styles["justify-content-between"],
              styles["border-bottom"],
              styles["navbar-expand-lg"],
              styles["navbar-light"]
            )}
          >
            <Link className={styles["navbar-brand"]} to={"/"}>
              <img src={Logo} alt="Vested Logo" height={30} />
            </Link>

            <Link
              className={classNames(styles["btn"], styles["btn-outline-dark"])}
              to={`/api/auth/logout`}
              reloadDocument
            >
              Kirjaudu ulos
            </Link>
          </nav>
          <div className="app-content">
            <Routes>
              <Route path="/" element={<MainPage user={user} />} />
            </Routes>
          </div>
        </>
      )}
    </div>
  );
}
